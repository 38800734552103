import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";
import { CallDisplayMode } from "./CallDisplayUI";

export interface CallModeMenuProps {
  activeMode: CallDisplayMode;
  setActiveMode: (mode: CallDisplayMode) => void;
}
export function CallModeMenu({
  activeMode,
  setActiveMode,
}: Readonly<CallModeMenuProps>) {
  return (
    <NavigationMenu
      value={activeMode}
      onValueChange={(value) => {
        if (
          ["ai", "transcript", "script", "summary", "report"].includes(value)
        ) {
          setActiveMode(value as CallDisplayMode);
        }
      }}
    >
      <NavigationMenuList>
        <NavigationMenuItem value="script">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "script" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("script");
            }}
          >
            Call
          </NavigationMenuLink>
        </NavigationMenuItem>

        <NavigationMenuItem value="ai">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "ai" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("ai");
            }}
          >
            AI
          </NavigationMenuLink>
        </NavigationMenuItem>

        <NavigationMenuItem value="transcript">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "transcript" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("transcript");
            }}
          >
            Transcript
          </NavigationMenuLink>
        </NavigationMenuItem>
        {/* <NavigationMenuItem value="report">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "report" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("report");
            }}
          >
            Report
          </NavigationMenuLink>
        </NavigationMenuItem> */}
      </NavigationMenuList>
    </NavigationMenu>
  );
}
