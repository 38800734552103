import { ZoomIntegration } from "./Integrations/ZoomIntegration";

export const IntegrationsManagerUI = () => {
  return (
    <div className="max-w-128 p-4">
      <h2 className="text-lg font-medium mb-4">Integrations</h2>
      <p className="text-sm text-gray-400 mb-4">
        Connect your Frontier account to third party services.
      </p>

      <div className="flex flex-col space-y-4">
        <ZoomIntegration />
      </div>
    </div>
  );
};
