import type {
  MeetingUrl,
  MicrosoftTeamsLiveUrl,
  MicrosoftTeamsUrl,
} from "party/types";
import { FaGoogle, FaMicrosoft, FaVideo } from "react-icons/fa";
import { SiZoom } from "react-icons/si";

interface CallServiceIconProps {
  url: string | MeetingUrl;
}

function getMicrosoftTeamsDisplayId(
  meetingUrl: MicrosoftTeamsUrl | MicrosoftTeamsLiveUrl
) {
  const { meeting_id, thread_id, message_id, business_meeting_id, tenant_id } =
    meetingUrl;
  if (meeting_id) {
    return meeting_id;
  }
  if (business_meeting_id) {
    return business_meeting_id;
  }
  if (thread_id) {
    return thread_id;
  }
  if (message_id) {
    return message_id;
  }
  return tenant_id ?? "Unknown";
}

function meetingUrlToDisplayId(meetingUrl: MeetingUrl) {
  const { platform, meeting_id } = meetingUrl;
  if (platform === "zoom" && meeting_id) {
    return meeting_id;
  }

  if (platform === "google_meet" && meeting_id) {
    return meeting_id;
  }

  if (platform === "microsoft_teams" || platform === "microsoft_teams_live") {
    return (
      getMicrosoftTeamsDisplayId(meetingUrl).split(":meeting_")[1] ?? "unknown"
    );
  }
  return "Unknown";
}

export function CallServiceIcon({ url }: Readonly<CallServiceIconProps>) {
  if (typeof url === "string") {
    let hostname;
    let pathname;
    try {
      const parsedUrl = new URL(url.trim());
      hostname = parsedUrl?.hostname;
      pathname = parsedUrl?.pathname;
    } catch (e) {
      console.error("Error parsing URL", e);
    }

    if (hostname?.includes("google")) {
      const roomId = pathname?.split("/").pop();
      return (
        <div className="flex items-center">
          <FaGoogle className="mr-2" />
          <span>{roomId}</span>
        </div>
      );
    } else if (hostname?.includes("zoom")) {
      const roomId = pathname?.split("/").pop();
      return (
        <div className="flex items-center">
          <SiZoom className="mr-2" />
          <span>{roomId}</span>
        </div>
      );
    } else if (hostname?.includes("teams.microsoft")) {
      const parts = pathname?.split("/").filter(Boolean);
      const roomIdContainer = parts
        ? parts?.length > 1
          ? parts[parts.length - 2]
          : undefined
        : undefined;
      const roomId = roomIdContainer?.split(":meeting_")[1] ?? "Unknown";
      return (
        <div className="flex items-center">
          <FaMicrosoft className="mr-2" />
          <span className="max-w-16 truncate" title={roomId}>
            {roomId.length > 16 ? roomId.slice(0, 13) + "..." : roomId}
          </span>
        </div>
      );
    } else {
      // Default case for unsupported or unknown services
      return (
        <div className="flex items-center">
          <FaVideo className="mr-2" />
          <span>{pathname ?? "Unknown"}</span>
        </div>
      );
    }
  } else {
    // Handle MeetingUrl object
    const displayId = meetingUrlToDisplayId(url);
    const { platform } = url;

    if (platform === "google_meet") {
      return (
        <div className="flex items-center">
          <FaGoogle className="mr-2" />
          <span>{displayId}</span>
        </div>
      );
    } else if (platform === "zoom") {
      return (
        <div className="flex items-center">
          <SiZoom className="mr-2" />
          <span>{displayId}</span>
        </div>
      );
    } else if (
      platform === "microsoft_teams" ||
      platform === "microsoft_teams_live"
    ) {
      return (
        <div className="flex items-center">
          <FaMicrosoft className="mr-2" />
          <span className="max-w-16 truncate" title={displayId}>
            {displayId.length > 16 ? displayId.slice(0, 13) + "..." : displayId}
          </span>
        </div>
      );
    } else {
      // Default case for unsupported or unknown platforms
      return (
        <div className="flex items-center">
          <FaVideo className="mr-2" />
          <span>{displayId}</span>
        </div>
      );
    }
  }
}
