import { useToast } from "@/components/hooks/use-toast";
import type { ScriptItemInsertionMessage } from "../../../schemas/ScriptItemInsertion";
import usePartySocket from "partysocket/react";
import type { BuyingSignalMessage } from "../../../schemas/BuyingSignal";
import { FeedbackMessageSchema } from "../../../schemas/FeedbackMessage";
import type { DigDeeperOpportunityMessage } from "schemas/DigDeeperOpportunity";
import type PartySocket from "partysocket";
import type { ClientMessage } from "party/clientMessageTypes";

export function useRealtimeFeedback(
  roomId: string,
  host: string,
  botSocket: PartySocket
) {
  const { toast } = useToast();

  const feedbackSocket = usePartySocket({
    host,
    room: roomId,
    party: "feedback",
    onOpen() {
      console.log("Feedback websocket connection opened for room:", roomId);
    },
    onClose() {
      console.warn("WebSocket connection closed for room:", roomId);
    },
    onError(error) {
      console.error("WebSocket error for room:", roomId, error);
    },
    onMessage(event) {
      const data = JSON.parse(event.data);
      const message = FeedbackMessageSchema.safeParse(data);
      if (message.success) {
        if (message.data.type !== "conversation_update") {
          console.log("Feedback", message.data);
        }
        if (message.data.type === "buying_signal_detected") {
          const buyingSignalMessage: BuyingSignalMessage = message.data;
          toast({
            title: "Buying Signal!",
            description: buyingSignalMessage.data.explanation,
          });
        }
        if (message.data.type === "script_item_insertion") {
          const scriptItem: ScriptItemInsertionMessage = message.data;
          toast({
            title: "Adding Script Item",
            description: scriptItem.data.text,
          });
          const insertMessage: ClientMessage = {
            type: "INSERT_SCRIPT_ITEM",
            payload: scriptItem.data,
            timestamp: new Date().toISOString(),
          };
          botSocket.send(JSON.stringify(insertMessage));
        }
        if (message.data.type === "dig_deeper_opportunity") {
          const scriptItem: DigDeeperOpportunityMessage = message.data;
          toast({
            title: "Dig deeper?",
            description: scriptItem.data.opportunity,
          });
        }
      }
    },
  });
  return feedbackSocket;
}
