import { useMemo } from "react";

export function isDesktop() {
  // This is a deployed web app
  if (window.location.protocol === "https:") {
    return false;
  }
  // This is a local development environment
  if (window.location.port.match(/^(80|443|1999|4545)$/)) {
    return false;
  }
  // This is a desktop app
  return true;
}

export function useIsDesktop() {
  return useMemo(() => {
    return isDesktop();
  }, []);
}
