import { z } from "zod";

export const ScriptItemInsertionSchema = z.object({
  text: z.string(),
  explanation: z.string(),
  confidence: z.number(),
});

export const ScriptItemInsertionMessageSchema = z.object({
  type: z.literal("script_item_insertion"),
  data: ScriptItemInsertionSchema,
});

export type ScriptItemInsertion = z.infer<typeof ScriptItemInsertionSchema>;
export type ScriptItemInsertionMessage = z.infer<
  typeof ScriptItemInsertionMessageSchema
>;
