import { useUser } from "@clerk/clerk-react";
import { useCallback, useEffect, useState } from "react";

import { AUTH_SERVICE_URL } from "./../../../publicEnv";
import { useIsDesktop } from "../../hooks/useIsDesktop";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { IntegrationConnectionStatus } from "./IntegrationConnectionStatus";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/hooks/use-toast";

export type VerificationStatus =
  | "unverified"
  | "verified"
  | "transferable"
  | "failed"
  | "expired";

export const ZoomIntegration = () => {
  const { user } = useUser();
  const { toast } = useToast();

  const [hasZoomAccount, setHasZoomAccount] = useState(false);
  const [zoomAccountStatus, setZoomAccountStatus] =
    useState<VerificationStatus | null>(null);
  const [hasAttemptedConnection, setHasAttemptedConnection] = useState(false);

  const handleConnect = useCallback(() => {
    setHasAttemptedConnection(true);
  }, [setHasAttemptedConnection]);

  const checkZoomAccount = useCallback(async () => {
    await user?.reload();
    if (user?.externalAccounts) {
      const zoomAccount = user?.externalAccounts.find(
        (account) => account.provider === "custom_zoom"
      );

      setHasZoomAccount(!!zoomAccount);
      setZoomAccountStatus(zoomAccount?.verification?.status ?? null);
    }
  }, [user, setHasZoomAccount, setZoomAccountStatus]);

  const handleManage = useCallback(() => {
    setHasAttemptedConnection(false);
  }, [checkZoomAccount]);

  useEffect(() => {
    checkZoomAccount();

    const handleVisibilityChange = async () => {
      if (!document.hidden) {
        // This code runs when the user returns to the app/tab
        await user?.reload();
        checkZoomAccount();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [checkZoomAccount, user]);

  useEffect(() => {
    const zoomAccount = user?.externalAccounts?.find(
      (account) => account.provider === "custom_zoom"
    );
    if (hasAttemptedConnection && zoomAccount && !hasZoomAccount) {
      toast({
        title: "Zoom connected",
        description: "You have successfully connected your Zoom account.",
        variant: "default",
      });
    }
    setHasZoomAccount(!!zoomAccount);
  }, [
    user?.externalAccounts,
    hasZoomAccount,
    setHasZoomAccount,
    hasAttemptedConnection,
  ]);

  return (
    <Card>
      <CardHeader>
        <div className="w-full flex flex-row">
          <div className="flex-grow font-semibold">Zoom</div>
          <div className="flex-grow-0">
            <IntegrationConnectionStatus
              status={zoomAccountStatus ? `${zoomAccountStatus}` : null}
              connected={hasZoomAccount && zoomAccountStatus === "verified"}
            />
          </div>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col space-y-4 w-full">
          <div className="text-sm text-gray-400">
            To allow Frontier to join your Zoom video calls you must connect
            your account.
          </div>
          <div>
            {hasZoomAccount ? (
              <ManageZoom onManage={handleManage} />
            ) : (
              <ConnectZoom onConnect={handleConnect} />
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const ManageZoom = ({ onManage }: { onManage: () => void }) => {
  const isDesktop = useIsDesktop();
  const handleManage = () => {
    const authUrl = `${AUTH_SERVICE_URL}integrations/zoom`;

    if (isDesktop && window.Neutralino) {
      window.Neutralino.os.open(authUrl);
    } else {
      window.open(authUrl, "_blank");
    }
    onManage();
  };
  return (
    <Button onClick={handleManage} variant="secondary">
      Manage
    </Button>
  );
};

export const ConnectZoom = ({ onConnect }: { onConnect: () => void }) => {
  const isDesktop = useIsDesktop();
  const [isConnecting, setIsConnecting] = useState(false);
  const handleConnect = () => {
    setIsConnecting(true);

    const authUrl = `${AUTH_SERVICE_URL}integrations/zoom`;

    if (isDesktop && window.Neutralino) {
      window.Neutralino.os.open(authUrl);
    } else {
      window.open(authUrl, "_blank");
    }
    onConnect();
  };

  return (
    <Button onClick={handleConnect} disabled={isConnecting} variant="default">
      Connect
    </Button>
  );
};
