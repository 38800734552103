import React, { useRef } from "react";
import { createPortal } from "react-dom";
import { useLocalStorage } from "usehooks-ts";

interface CallManagerWrapperProps {
  children: React.ReactNode;
  verticalMargin?: string;
}

const PortalContext =
  React.createContext<React.RefObject<HTMLDivElement> | null>(null);

export function CallManagerWrapper({
  children,
  verticalMargin = "lg:py-8",
}: Readonly<CallManagerWrapperProps>) {
  const [isTesting] = useLocalStorage("testing-mode", false);
  const portalRef = useRef<HTMLDivElement>(null);

  return (
    <PortalContext.Provider value={portalRef}>
      <div
        className={`lg:bg-slate-200 h-screen w-full ${verticalMargin} flex flex-col ${isTesting ? "lg:p-8" : ""}`}
      >
        <div
          className={`flex-grow overflow-hidden flex ${!isTesting ? "justify-center" : ""}`}
        >
          <div
            className={`h-full transition-all duration-300 ease-in-out 
            ${isTesting ? "w-1/2" : "w-full max-w-2xl"} bg-white rounded-md`}
          >
            {children}
          </div>
          <div
            className={`h-full transition-all duration-300 ease-in-out 
            ${isTesting ? "w-1/2 ml-4" : "w-0 ml-0"} bg-gray-100 overflow-hidden`}
          >
            <div ref={portalRef} className="h-full w-full" />
          </div>
        </div>
      </div>
    </PortalContext.Provider>
  );
}

export const TestPortal: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const portalRef = React.useContext(PortalContext);
  const [isTesting] = useLocalStorage("testing-mode", false);

  if (!portalRef?.current) return null;

  return createPortal(
    <div className={`${isTesting ? "block" : "hidden"}`}>{children}</div>,
    portalRef.current
  );
};
