import { BuyingSignalMessageSchema } from "./BuyingSignal";
import { ConversationUpdateSchema } from "./ConversationUpdate";
import { z } from "zod";
import { DigDeeperOpportunityMessageSchema } from "./DigDeeperOpportunity";
import { ScriptItemInsertionMessageSchema } from "./ScriptItemInsertion";

export const FeedbackMessageSchema = z.discriminatedUnion("type", [
  BuyingSignalMessageSchema,
  ConversationUpdateSchema,
  DigDeeperOpportunityMessageSchema,
  ScriptItemInsertionMessageSchema,
]);

export type FeedbackMessage = z.infer<typeof FeedbackMessageSchema>;
