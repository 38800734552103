import { useIsDesktop } from "./useIsDesktop";

export const useOpenUrl = () => {
  const isDesktop = useIsDesktop();
  if (isDesktop && window.Neutralino) {
    return (url: string) => {
      window.Neutralino?.os.open(url);
    };
  } else {
    return (url: string) => {
      window.open(url, "_blank");
    };
  }
};
