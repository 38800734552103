import { Button } from "@/components/ui/button";
import type { CallDisplayMode } from "./CallDisplayUI";
import { X } from "lucide-react";

export const CloseTabButton = ({
  setActiveMode,
}: {
  setActiveMode: (mode: CallDisplayMode) => void;
}) => {
  return (
    <Button
      size="sm"
      variant="secondary"
      onClick={() => setActiveMode("script")}
    >
      <X size={16} />
    </Button>
  );
};
