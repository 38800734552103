import { useAnalytics } from "../AnalyticsProvider";
import {
  TranscriptUpdateSchema,
  type TranscriptUpdate,
} from "../../../schemas/TranscriptUpdate";
import { ServerMessageSchema } from "../../../schemas/ServerMessage";
import {
  PlaybookProgressSchema,
  PlaybookSectionSchema,
  type PlaybookProgress,
  type RecallBot,
  type AIQuestionAnswer,
  type PartialAIResponse,
  type AILatencyMetrics,
  StatusChangeSchema,
  RecallBotSchema,
  AIQuestionAnswerSchema,
  type PlaybookScriptItem,
  CallQuestion,
  type AIStreamingStatus,
  type MeetingParticipant,
} from "party/types";
import usePartySocket from "partysocket/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { z } from "zod";
import type {
  BaseClientMessage,
  ClientMessage,
} from "party/clientMessageTypes";
import { useBotStatusPolling } from "./useBotStatusPolling";
import {
  HandleGetHelpFunction,
  type HandleGetHelpFunctionArgs,
} from "./AiHelpCard";
import { SummaryOutput } from "party/logic/SummaryGenerator/SummaryGenerator";
import { updateTranscriptArray } from "../utils/updateTranscript";
import { useRealtimeFeedback } from "./useRealtimeFeedback";
import { useLocalStorage } from "usehooks-ts";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import type PartySocket from "partysocket";

export type PartyKitServerConnectionStatus =
  | "connected"
  | "disconnected"
  | "reconnecting";

export type UseCallDisplayReturn = {
  botSocket: PartySocket;
  transcript: TranscriptUpdate[];
  roomBot: RecallBot | null;
  aiHistory: AIQuestionAnswer[];
  error: string | null;
  clearError: () => void;
  streamingResponse: PartialAIResponse | null;
  isStreaming: boolean;
  streamingStatus: AIStreamingStatus | undefined;
  latencyMetrics: AILatencyMetrics | undefined;
  messageLatency: number;
  handleGetHelp: HandleGetHelpFunction;
  handleRequestBotInfo: () => void;
  handleCompleteScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleSetCurrentScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleToggleProcessScript: () => void;
  handleRequestSummary: () => void;
  participants: MeetingParticipant[];
  playbook: PlaybookProgress;
  processScript: boolean;
  currentScriptItem: PlaybookScriptItem | undefined;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
  isPolling: boolean;
  questions: CallQuestion[];
  reconnectAttempt: number;
  summary: SummaryOutput | null;
  currentAiHelpRequest: HandleGetHelpFunctionArgs | undefined;
};

export function useCallDisplay(
  roomId: string,
  host: string
): UseCallDisplayReturn {
  const { trackEvent } = useAnalytics();
  const [transcript, setTranscript] = useState<TranscriptUpdate[]>([]);
  const [participants, setParticipants] = useState<MeetingParticipant[]>([]);
  const [processScript, setProcessScript] = useState<boolean>(false);
  const [roomBot, setRoomBot] = useState<RecallBot | null>(null);
  const [aiHistory, setAIHistory] = useState<AIQuestionAnswer[]>([]);
  const [questions, setQuestions] = useState<CallQuestion[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [streamingResponse, setStreamingResponse] =
    useState<PartialAIResponse | null>(null);
  const [isStreaming, setIsStreaming] = useState<boolean>(false);
  const [streamingStatus, setStreamingStatus] = useState<
    AIStreamingStatus | undefined
  >(undefined);
  const [currentAiHelpRequest, setCurrentAiHelpRequest] = useState<
    HandleGetHelpFunctionArgs | undefined
  >(undefined);
  const streamingTimeoutRef = useRef<number | null>(null);
  const [messageLatency, setMessageLatency] = useState(0);
  const resetStreamingTimeout = useCallback(() => {
    if (isStreaming) {
      if (streamingTimeoutRef.current !== null) {
        window.clearTimeout(streamingTimeoutRef.current);
      }
      streamingTimeoutRef.current = window.setTimeout(() => {
        if (isStreaming) {
          setIsStreaming(false);
          setCurrentAiHelpRequest(undefined);
          setStreamingResponse(null);
        }
      }, 20000);
    } // 20 seconds timeout
  }, [isStreaming, setCurrentAiHelpRequest]);

  const [latencyMetrics, setLatencyMetrics] = useState<
    AILatencyMetrics | undefined
  >(undefined);
  const [playbook, setPlaybook] = useState<PlaybookProgress>({
    sections: [],
    currentSectionId: null,
    status: "in_progress",
  });

  const clearError = useCallback(() => setError(null), []);

  const [currentScriptItem, setCurrentScriptItem] =
    useState<PlaybookScriptItem | null>(null);

  const [partyKitServerConnectionStatus, setPartyKitServerConnectionStatus] =
    useState<PartyKitServerConnectionStatus>("disconnected");
  const [reconnectAttempt, setReconnectAttempt] = useState(0);

  const [summary, setSummary] = useState<SummaryOutput | null>(null);
  useEffect(() => {
    const handleOnline = () => {
      console.log("Network is online. Attempting to reconnect...");
      // Attempt to reconnect?
    };

    const handleOffline = () => {
      console.log("Network is offline. WebSocket may disconnect.");
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const [useRealtimeAPI, setUseRealtimeAPI] = useLocalStorage(
    "use-realtime-api",
    false
  );

  const hasRealtimeFeatureFlag = useFeatureFlag("realtime_api");

  const botSocket = usePartySocket({
    host,
    room: roomId,
    party: "call",
    onOpen() {
      console.log("WebSocket connection opened for room:", roomId);
      setPartyKitServerConnectionStatus("connected");
      setReconnectAttempt(0);
    },
    onClose() {
      console.warn("WebSocket connection closed for room:", roomId);
      setPartyKitServerConnectionStatus("disconnected");
      handleReconnect();
    },
    onError(error) {
      console.error("WebSocket error for room:", roomId, error);
    },
    onMessage(event) {
      try {
        const data = JSON.parse(event.data);
        const message = ServerMessageSchema.parse(data);
        const messageCopy = JSON.parse(JSON.stringify(message));
        console.log(message.type, messageCopy);

        switch (message.type) {
          case "CALL_SUMMARY":
            {
              setSummary(message.payload);
            }
            break;
          case "PROCESS_SCRIPT":
            {
              setProcessScript(message.payload);
            }
            break;
          case "FULL_TRANSCRIPT":
            {
              setTranscript(
                z.array(TranscriptUpdateSchema).parse(message.payload)
              );
            }
            break;
          case "TRANSCRIPT_UPDATE":
            {
              setTranscript((prev) => {
                console.log(
                  "Setting transcript",
                  prev.map((item) => `${item.id}:${item.is_final}`)
                );
                const updatedItem = TranscriptUpdateSchema.parse(
                  message.payload
                );
                // Only update final items
                // if (!updatedItem.is_final) {
                //   return prev;
                // }

                if (hasRealtimeFeatureFlag && useRealtimeAPI) {
                  if (updatedItem.is_final) {
                    console.log("Sending transcript update to feedback server");
                    feedbackSocket.send(
                      JSON.stringify({
                        type: "TRANSCRIPT_UPDATE",
                        payload: updatedItem,
                        timestamp: new Date().toISOString(),
                      })
                    );
                  }
                } else {
                  console.log("Realtime API disabled");
                }
                return updateTranscriptArray(prev, updatedItem);
              });
            }
            break;
          case "BOT_INFO":
            {
              setRoomBot(RecallBotSchema.parse(message.payload));
            }
            break;
          case "STATUS_UPDATE": {
            const newStatus = StatusChangeSchema.parse(message.payload.status);
            setRoomBot((prevBot) =>
              prevBot
                ? {
                    ...prevBot,
                    status_changes: [
                      ...(prevBot.status_changes ?? []),
                      newStatus,
                    ],
                  }
                : null
            );
            break;
          }
          case "AI_HISTORY":
            {
              setAIHistory(message.payload);
            }
            break;
          case "AI_HELP_STREAM_INFO":
            {
              setStreamingStatus(message.payload);
            }
            break;
          case "AI_HELP_STREAM":
            setIsStreaming(true);
            setStreamingResponse(message.payload);
            resetStreamingTimeout();
            break;
          case "AI_ERROR": {
            setIsStreaming(false);
            setStreamingStatus(undefined);
            setCurrentAiHelpRequest(undefined);
            setStreamingResponse(null);
            if (streamingTimeoutRef.current !== null) {
              window.clearTimeout(streamingTimeoutRef.current);
            }
            break;
          }
          case "AI_HELP_STREAM_END": {
            setIsStreaming(false);
            setStreamingResponse(message.payload);
            setStreamingStatus(undefined);
            setCurrentAiHelpRequest(undefined);
            if (streamingTimeoutRef.current !== null) {
              window.clearTimeout(streamingTimeoutRef.current);
            }
            const parsed = AIQuestionAnswerSchema.safeParse(message.payload);
            if (parsed.success) {
              setAIHistory((prevHistory) => [...prevHistory, parsed.data]);
            }
            setStreamingResponse(null);

            break;
          }
          case "MESSAGE_LATENCY":
            console.log("Message Latency", message.payload);
            setMessageLatency(message.payload);
            break;
          case "AI_LATENCY_METRICS":
            setLatencyMetrics(message.payload);
            trackEvent("room_ai_latency_metrics", message.payload);
            break;
          case "ERROR":
            setError(message.payload.message);
            setIsStreaming(false);
            if (streamingTimeoutRef.current !== null) {
              window.clearTimeout(streamingTimeoutRef.current);
            }
            console.error(
              "Received error from server:",
              message.payload.message
            );
            trackEvent("room_error", { message: message.payload.message });
            break;
          case "PLAYBOOK_PROGRESS":
            setPlaybook(PlaybookProgressSchema.parse(message.payload));
            break;
          case "CURRENT_PLAYBOOK_SECTION":
            setPlaybook((prev) => {
              return {
                ...prev,
                currentSectionId: message.payload,
              };
            });
            break;
          case "PLAYBOOK_SECTION_UPDATE":
            setPlaybook((prev) => {
              const updatedSection = PlaybookSectionSchema.parse(
                message.payload
              );
              return {
                ...prev,
                sections: prev.sections.map((section) =>
                  section.id === updatedSection.id ? updatedSection : section
                ),
              };
            });
            break;
          case "CURRENT_SCRIPT_ITEM":
            console.log("Setting current script item:", message.payload);
            setCurrentScriptItem(message.payload);
            break;
          case "CALL_QUESTIONS_UPDATE":
            setQuestions(data.payload);
            break;
          case "PARTICIPANTS":
            setParticipants(data.payload);
            break;
          case "PLAYBOOK_COMPLETE":
            // Handle playbook completion (e.g., show a completion message)
            break;
          default:
            console.warn("Unhandled message type:", (message as any).type);
        }
      } catch (error) {
        if (error instanceof z.ZodError) {
          console.error("Invalid message format:", error.errors);
        } else {
          console.error("Error processing message:", error);
        }
      }
    },
  });

  const feedbackSocket = useRealtimeFeedback(roomId, host, botSocket);

  const handleReconnect = useCallback(() => {
    setPartyKitServerConnectionStatus("reconnecting");
    const backoffTime = Math.min(1000 * 2 ** reconnectAttempt, 30000); // Max 30 seconds
    setTimeout(() => {
      setReconnectAttempt((prev) => prev + 1);
      botSocket.reconnect();
    }, backoffTime);
  }, [botSocket, reconnectAttempt]);

  useEffect(() => {
    console.log("Current script item:", currentScriptItem);
  }, [currentScriptItem]);

  const sendClientEvent = useCallback(
    (message: BaseClientMessage) => {
      const messageWithTimestamp: ClientMessage = {
        ...message,
        timestamp: new Date().toISOString(),
      };
      console.log("Sending client event:", messageWithTimestamp);
      botSocket.send(JSON.stringify(messageWithTimestamp));
    },
    [botSocket]
  );
  const handleToggleProcessScript = useCallback(() => {
    setProcessScript((prevProcessScript) => {
      const newProcessScript = !prevProcessScript;
      sendClientEvent({ type: "PROCESS_SCRIPT", payload: newProcessScript });
      return newProcessScript;
    });
  }, [setProcessScript, sendClientEvent]);

  const handleGetHelp = useCallback(
    (args: HandleGetHelpFunctionArgs) => {
      const { question, transcriptItems, transcriptItemIds, detectedQuestion } =
        args;

      const message: ClientMessage = {
        type: "GET_HELP",
        timestamp: new Date().toISOString(),
        payload: {
          question,
          transcriptItemIds:
            transcriptItemIds ?? transcriptItems?.map((item) => item.id),
          detectedQuestion,
        },
      };

      setCurrentAiHelpRequest(args);
      sendClientEvent(message);
      setIsStreaming(true);
      setStreamingResponse(null);
      setError(null);
      resetStreamingTimeout();
    },
    [sendClientEvent]
  );

  useEffect(() => {
    return () => {
      if (streamingTimeoutRef.current !== null) {
        window.clearTimeout(streamingTimeoutRef.current);
      }
    };
  }, []);

  const handleRequestBotInfo = useCallback(() => {
    sendClientEvent({ type: "REQUEST_BOT_INFO" });
  }, [sendClientEvent]);

  const handleRequestSummary = useCallback(() => {
    sendClientEvent({ type: "REQUEST_SUMMARY" });
  }, [sendClientEvent]);

  const handleCompleteScriptItem = useCallback(
    (scriptItem?: PlaybookScriptItem) => {
      if (!scriptItem) {
        return;
      }
      if (scriptItem.completed) {
        sendClientEvent({
          type: "UNCOMPLETE_SCRIPT_ITEM",
          payload: scriptItem,
        });
      } else {
        sendClientEvent({
          type: "COMPLETE_SCRIPT_ITEM",
          payload: scriptItem,
        });
      }
    },
    [sendClientEvent]
  );

  const handleSetCurrentScriptItem = useCallback(
    (scriptItem?: PlaybookScriptItem) => {
      if (!scriptItem) {
        return;
      }
      sendClientEvent({
        type: "SET_CURRENT_SCRIPT_ITEM",
        payload: scriptItem,
      });
    },
    [sendClientEvent]
  );

  const { isPolling } = useBotStatusPolling({
    roomBot,
    onPoll: handleRequestBotInfo,
  });

  return {
    botSocket,
    transcript,
    roomBot,
    aiHistory,
    error,
    clearError,
    streamingResponse,
    isStreaming,
    streamingStatus,
    latencyMetrics,
    messageLatency,
    handleGetHelp,
    handleRequestBotInfo,
    handleCompleteScriptItem,
    handleSetCurrentScriptItem,
    handleToggleProcessScript,
    handleRequestSummary,
    playbook,
    participants,
    processScript,
    currentScriptItem: currentScriptItem ?? undefined,
    partyKitServerConnectionStatus,
    isPolling,
    questions,
    reconnectAttempt,
    summary,
    currentAiHelpRequest,
  };
}
