import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { useCallback, type ReactNode } from "react";
import { useNeutralino } from "./Neutralino/NeutralinoContextProvider";
import { useLocalStorage } from "usehooks-ts";
import { Switch } from "@/components/ui/switch";
import { useIsDesktop } from "../hooks/useIsDesktop";
import { useFeatureFlag } from "../hooks/useFeatureFlag";

export const AppSettingsUI = () => {
  const isDesktop = useIsDesktop();
  const hasRealtimeAPI = useFeatureFlag("realtime-api");
  return (
    <div className="max-w-128 p-4">
      <h2 className="text-lg font-medium mb-4">App settings</h2>
      <p className="text-sm text-gray-400 mb-4">
        Configure how you use the Frontier X app.
      </p>

      <div className="flex flex-col space-y-4">
        <AlwaysOnTopSwitch enabled={isDesktop} />
        <UseRealtimeAPISwitch enabled={!!hasRealtimeAPI} />
      </div>
    </div>
  );
};

export type AppSettingsCardProps = {
  children: ReactNode;
  title: string;
  description: string;
};

export const AlwaysOnTopSwitch = ({
  enabled,
}: Readonly<{ enabled: boolean }>) => {
  const { isAlwaysOnTop, toggleAlwaysOnTop } = useNeutralino();
  const isDesktop = useIsDesktop();

  const handleSwitch = useCallback(() => {
    console.log("handleSwitch");
    toggleAlwaysOnTop();
  }, [isAlwaysOnTop, toggleAlwaysOnTop]);

  return (
    <AppSettingsCard
      title="Always on top"
      description="Make the desktop application sit on top of all other windows."
    >
      <Switch
        checked={isDesktop && isAlwaysOnTop}
        onCheckedChange={handleSwitch}
        disabled={!enabled}
      />
    </AppSettingsCard>
  );
};

export const UseRealtimeAPISwitch = ({
  enabled,
}: Readonly<{ enabled: boolean }>) => {
  const [useRealtimeAPI, setUseRealtimeAPI] = useLocalStorage(
    "use-realtime-api",
    false
  );
  return (
    <AppSettingsCard
      title="Use Realtime API"
      description="Enable the Realtime API to receive updates in real-time."
    >
      <Switch
        checked={useRealtimeAPI}
        onCheckedChange={() => {
          setUseRealtimeAPI(!useRealtimeAPI);
        }}
        disabled={!enabled}
      />
    </AppSettingsCard>
  );
};

export const AppSettingsCard = ({
  children,
  title,
  description,
}: Readonly<AppSettingsCardProps>) => {
  return (
    <Card>
      <CardHeader className="p-4 border-b border-gray-200">{title}</CardHeader>
      <CardDescription className="p-4">
        <div className="flex flex-row space-x-4 w-full">
          <div className="text-sm text-gray-400 flex-grow">{description}</div>
          <div className="flex-grow-0">{children}</div>
        </div>
      </CardDescription>
    </Card>
  );
};
