import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { FilterIcon } from "lucide-react";
import { useCallback } from "react";

export type GuestHostFilter = "all" | "host" | "guest" | "unknown";

export const GuestHostFilterDropdown = ({
  value,
  onSelect,
}: {
  value: GuestHostFilter;
  onSelect: (value: GuestHostFilter) => void;
}) => {
  const handleChoice = useCallback(
    (value: GuestHostFilter) => {
      onSelect(value);
    },
    [onSelect]
  );
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="ghost">
          <span className="mr-2">
            {value.charAt(0).toUpperCase() + value.slice(1)}
          </span>{" "}
          <FilterIcon size={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => handleChoice("guest")}>
          Guest
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleChoice("host")}>
          Host
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleChoice("unknown")}>
          Unknown
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => handleChoice("all")}>
          All
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
