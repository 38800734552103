import type { TranscriptUpdate } from "../../../schemas/TranscriptUpdate";

export function updateTranscriptArray(
  prev: TranscriptUpdate[],
  updatedItem: TranscriptUpdate
) {
  const reversedIndex = prev
    .slice()
    .reverse()
    .findIndex(
      (item) => item.speaker_id == updatedItem.speaker_id && !item.is_final
    );

  const incompleteItemIndex =
    reversedIndex === -1 ? -1 : prev.length - 1 - reversedIndex;
  if (updatedItem.is_final) {
    // Should be the last item
    if (incompleteItemIndex !== -1) {
      console.log(
        "Finishing incomplete item",
        prev[incompleteItemIndex],
        updatedItem
      );
      prev[incompleteItemIndex] = updatedItem;
      return [...prev];
    }
    console.log("Inserting complete item", updatedItem);
    return [...prev, updatedItem];
  } else {
    if (incompleteItemIndex !== -1) {
      console.log(
        "Updating incomplete item",
        prev[incompleteItemIndex],
        updatedItem
      );
      prev[incompleteItemIndex] = updatedItem;
      return [...prev];
    }
    console.log("Inserting incomplete item", updatedItem);
    return [...prev, updatedItem];
  }
}
