import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Loader2,
  Menu,
  Sparkles,
  X,
  Ear,
  EarOff,
  Bug,
  BugOff,
  DoorOpen,
  EllipsisVertical,
  CheckSquare,
  Check,
  HelpCircle,
} from "lucide-react";

import { CallServiceIcon } from "./CallServiceIcon";
import { type TranscriptUpdate } from "../../../schemas/TranscriptUpdate";
import type {
  RecallBot,
  AIQuestionAnswer,
  PartialAIResponse,
  AILatencyMetrics,
  PlaybookProgress,
  PlaybookScriptItem,
  CallQuestion,
  AIStreamingStatus,
  MeetingParticipant,
} from "party/types";
import { CallStatus } from "./CallStatus";
import { useCallback, useState } from "react";
import { CallModeMenu } from "./CallModeMenu";
import { ErrorNotice } from "./ErrorNotice";
import { TranscriptTable } from "./TranscriptTable";
import { AiHelpBrowser } from "./AiHelpBrowser";
import { LoadingSpinner } from "./LoadingSpinner";
import { PlaybookScriptDisplay } from "./PlaybookScriptDisplay";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { PartyKitServerConnectionStatus } from "./useCallDisplay";
import { QuestionDisplay } from "./QuestionDisplay";
import {
  HandleGetHelpFunction,
  type HandleGetHelpFunctionArgs,
} from "./AiHelpCard";
import { CallResultsReport } from "./CallResultsReport";
import { SummaryOutput } from "party/logic/SummaryGenerator/SummaryGenerator";

import type PartySocket from "partysocket";
import { useLocalStorage } from "usehooks-ts";
import { useFeatureFlag } from "../hooks/useFeatureFlag";
import { useOpenUrl } from "../hooks/useOpenUrl";
import { CallDoneDialog } from "./CallDoneDialog";
import { CloseTabButton } from "./CloseTabButton";

export type CallDisplayMode =
  | "ai"
  | "transcript"
  | "script"
  | "report"
  | "summary";

export interface CallDisplayUIProps {
  transcript: TranscriptUpdate[];
  roomBot: RecallBot | null;
  aiHistory: AIQuestionAnswer[];
  host: string;
  roomId: string;
  error: string | null;
  clearError?: () => void;
  streamingResponse: PartialAIResponse | null;
  isStreaming: boolean;
  streamingStatus: AIStreamingStatus | undefined;
  latencyMetrics?: AILatencyMetrics;
  messageLatency: number;
  playbook: PlaybookProgress;
  participants: MeetingParticipant[];
  summary: SummaryOutput | null;
  processScript: boolean;
  currentScriptItem?: PlaybookScriptItem;
  handleGetHelp: HandleGetHelpFunction;
  handleRequestBotInfo: () => void;
  handleRequestSummary: () => void;
  handleToggleProcessScript: () => void;
  leaveCall: () => void;
  handleCompleteScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleSetCurrentScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  mainSocket: PartySocket;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
  currentAiHelpRequest: HandleGetHelpFunctionArgs | undefined;
}

const ToggleProcessScript = ({
  processScript,
  handleToggleProcessScript,
}: {
  handleToggleProcessScript: () => void;
  processScript: boolean;
}) => {
  return (
    <DropdownMenuItem onClick={handleToggleProcessScript}>
      {processScript ? (
        <EarOff size={12} className="mr-2" />
      ) : (
        <Ear size={12} className="mr-2" />
      )}
      {` ${processScript ? "Disable transcript" : "Enable transcript"}`}
    </DropdownMenuItem>
  );
};

const ToggleTestingMode = () => {
  const [isTesting, setIsTesting] = useLocalStorage("testing-mode", false);
  return (
    <DropdownMenuItem onClick={() => setIsTesting(!isTesting)}>
      {isTesting ? (
        <BugOff size={12} className="mr-2" />
      ) : (
        <Bug size={12} className="mr-2" />
      )}
      {`${isTesting ? "Disable" : "Enable"} test panel`}
    </DropdownMenuItem>
  );
};

const MainContent = ({
  host,
  roomId,
  roomBot,
  error,
  clearError,
  streamingResponse,
  latencyMetrics,
  aiHistory,
  transcript,
  handleGetHelp,
  activeMode,
  setActiveMode,
  mainSocket,
  participants,
  playbook,
  summary,
  currentScriptItem,
  handleCompleteScriptItem,
  handleSetCurrentScriptItem,
  handleRequestBotInfo,
  handleRequestSummary,
  partyKitServerConnectionStatus,
  currentAiHelpRequest,
  isStreaming,
  streamingStatus,
}: Readonly<{
  host: string;
  roomId: string;
  roomBot: RecallBot | undefined;
  error: string | null;
  clearError?: () => void;
  streamingResponse: PartialAIResponse | null;
  isStreaming: boolean;
  streamingStatus: AIStreamingStatus | undefined;
  latencyMetrics?: AILatencyMetrics;
  aiHistory: AIQuestionAnswer[];
  transcript: TranscriptUpdate[];
  handleGetHelp: HandleGetHelpFunction;
  activeMode: CallDisplayMode;
  setActiveMode: React.Dispatch<React.SetStateAction<CallDisplayMode>>;
  participants: MeetingParticipant[];
  mainSocket: PartySocket;
  playbook: PlaybookProgress;
  summary: SummaryOutput | null;
  currentScriptItem?: PlaybookScriptItem;
  handleCompleteScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleSetCurrentScriptItem: (scriptItem?: PlaybookScriptItem) => void;
  handleRequestBotInfo: () => void;
  handleRequestSummary: () => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
  currentAiHelpRequest: HandleGetHelpFunctionArgs | undefined;
}>) => {
  const hasJoinedCall = roomBot?.status_changes?.some(
    (status) => status.code === "in_call_recording"
  );

  return (
    <div className="flex-grow overflow-y-auto">
      <div className="h-full flex flex-col">
        {error && (
          <div className="mb-2">
            <ErrorNotice error={error} clearError={clearError} />
          </div>
        )}

        <div
          className={`${activeMode === "script" ? "block" : "hidden"} flex-grow flex-1 min-h-0 flex flex-col`}
        >
          <div className="flex-1 min-h-0">
            <PlaybookScriptDisplay
              handleSetCurrentScriptItem={handleSetCurrentScriptItem}
              currentScriptItem={currentScriptItem}
              playbook={playbook}
              handleCompleteScriptItem={handleCompleteScriptItem}
              mainSocket={mainSocket}
              roomId={roomId}
            />
          </div>
          <div className="flex-grow flex-1 min-h-0">
            {hasJoinedCall ? (
              <QuestionDisplay
                roomBot={roomBot}
                onGetAIHelp={handleGetHelp}
                host={host}
                roomId={roomId}
                aiHistory={aiHistory}
                isStreaming={isStreaming}
                handleGetHelp={handleGetHelp}
                setActiveMode={setActiveMode}
              />
            ) : (
              <WaitingForBot
                roomBot={roomBot}
                handleRequestBotInfo={handleRequestBotInfo}
                partyKitServerConnectionStatus={partyKitServerConnectionStatus}
              />
            )}
          </div>
        </div>
        <div className={`${activeMode === "ai" ? "block" : "hidden"}`}>
          <AiHelpBrowser
            history={aiHistory}
            latencyMetrics={latencyMetrics}
            handleGetHelp={handleGetHelp}
            currentAiHelpRequest={currentAiHelpRequest}
            isStreaming={isStreaming}
            streamingResponse={streamingResponse}
            streamingStatus={streamingStatus}
            setActiveMode={setActiveMode}
          />
        </div>

        <div
          className={`${activeMode === "transcript" ? "block" : "hidden"} flex flex-col h-full flex-grow`}
        >
          <TranscriptTable
            transcript={transcript}
            handleGetHelp={handleGetHelp}
            participants={participants}
            setActiveMode={setActiveMode}
          />
        </div>
        <div
          className={`${activeMode === "report" ? "block" : "hidden"} relative`}
        >
          <div className="absolute top-2 right-2">
            <CloseTabButton setActiveMode={setActiveMode} />
          </div>
          <div className="flex flex-col h-full space-y-8">
            <div className="pb-8">
              {activeMode === "report" && (
                <CallResultsReport
                  setActiveMode={setActiveMode}
                  handleRequestSummary={handleRequestSummary}
                  summary={summary}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const WaitingForBot = ({
  roomBot,
  handleRequestBotInfo,
  partyKitServerConnectionStatus,
}: {
  roomBot: RecallBot | undefined;
  handleRequestBotInfo: () => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
}) => {
  return (
    <div className="h-full flex flex-col">
      <div className="flex flex-col w-full space-y-2">
        <div className="flex-grow-0">
          <h2 className="mb-2">&nbsp;</h2>
        </div>
        <div className="flex-grow overflow-hidden flex flex-col space-y-8">
          <div>
            <div className="py-8">
              <LoadingSpinner reason={"waiting for bot"} delay={0} />
            </div>
            <div className="text-center py-8">Waiting to join…</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Footer = ({
  handleRequestBotInfo = () => {},
  roomBot,
  partyKitServerConnectionStatus,
  activeMode,
  setActiveMode,
  processScript,
  handleToggleProcessScript,
  leaveCall,
}: Readonly<{
  handleRequestBotInfo: () => void;
  roomBot: RecallBot | undefined;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
  activeMode: CallDisplayMode;
  setActiveMode: React.Dispatch<React.SetStateAction<CallDisplayMode>>;
  processScript: boolean;
  handleToggleProcessScript: () => void;
  leaveCall: () => void;
}>) => {
  const canTest = useFeatureFlag("testing-mode");

  const openUrl = useOpenUrl();

  const status = roomBot?.status_changes?.[
    roomBot.status_changes.length - 1
  ] ?? { code: "starting_up" };

  return (
    <div className="flex justify-between items-center p-2 border-t border-gray-300">
      <div className="flex flex-row space-x-4">
        <CallStatus
          status={status?.code}
          handleRequestBotInfo={handleRequestBotInfo}
          partyKitServerConnectionStatus={partyKitServerConnectionStatus}
        />
      </div>
      <div className="flex justify-center">
        <CallModeMenu activeMode={activeMode} setActiveMode={setActiveMode} />
      </div>
      <div className="flex flex-row space-x-2">
        <div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button size="sm" variant="ghost">
                <EllipsisVertical size={16} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {/* <DropdownMenuItem>Latency: {messageLatency} ms</DropdownMenuItem> */}
              {canTest && <ToggleTestingMode />}
              <ToggleProcessScript
                processScript={processScript}
                handleToggleProcessScript={handleToggleProcessScript}
              />
              <DropdownMenuItem
                onClick={() => openUrl("https://intercom.help/frontier-ai/en")}
              >
                <HelpCircle size={12} className="mr-2" /> Help & support
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div>
          <CallDoneDialog leaveCall={leaveCall} setActiveMode={setActiveMode} />
        </div>
      </div>
    </div>
  );
};

export function CallDisplayUI({
  mainSocket,
  host,
  roomId,
  transcript,
  roomBot,
  aiHistory,
  error,
  clearError,
  streamingResponse,
  isStreaming,
  streamingStatus,
  latencyMetrics,
  messageLatency,
  handleGetHelp,
  handleRequestBotInfo = () => {},
  handleRequestSummary = () => {},
  handleCompleteScriptItem = () => {},
  handleSetCurrentScriptItem = () => {},
  handleToggleProcessScript = () => {},
  leaveCall,
  participants,
  playbook,
  summary,
  processScript,
  currentScriptItem,
  partyKitServerConnectionStatus,
  currentAiHelpRequest,
}: Readonly<CallDisplayUIProps>) {
  const [activeMode, setActiveMode] = useState<CallDisplayMode>("script");

  const handleGetAIHelp = useCallback(
    (args: HandleGetHelpFunctionArgs) => {
      console.log("Getting AI help");
      setActiveMode("ai");
      handleGetHelp(args);
    },
    [setActiveMode, handleGetHelp]
  );

  return (
    <div className="flex flex-col h-full">
      <MainContent
        mainSocket={mainSocket}
        host={host}
        roomId={roomId}
        roomBot={roomBot ?? undefined}
        error={error}
        clearError={clearError}
        isStreaming={isStreaming}
        streamingStatus={streamingStatus}
        streamingResponse={streamingResponse}
        latencyMetrics={latencyMetrics}
        aiHistory={aiHistory}
        participants={participants}
        transcript={transcript}
        summary={summary}
        handleGetHelp={handleGetAIHelp}
        activeMode={activeMode}
        setActiveMode={setActiveMode}
        playbook={playbook}
        currentScriptItem={currentScriptItem}
        handleCompleteScriptItem={handleCompleteScriptItem}
        handleSetCurrentScriptItem={handleSetCurrentScriptItem}
        handleRequestBotInfo={handleRequestBotInfo}
        handleRequestSummary={handleRequestSummary}
        partyKitServerConnectionStatus={partyKitServerConnectionStatus}
        currentAiHelpRequest={currentAiHelpRequest}
      />
      <Footer
        handleRequestBotInfo={handleRequestBotInfo}
        roomBot={roomBot ?? undefined}
        partyKitServerConnectionStatus={partyKitServerConnectionStatus}
        activeMode={activeMode}
        setActiveMode={setActiveMode}
        handleToggleProcessScript={handleToggleProcessScript}
        processScript={processScript}
        leaveCall={leaveCall}
      />
    </div>
  );
}
