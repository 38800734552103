import type { TranscriptUpdate } from "schemas/TranscriptUpdate";

export const LatencyDisplay = ({
  transcript,
}: {
  transcript: TranscriptUpdate[];
}) => {
  // const latestStartLatency =
  //   transcript.length > 0
  //     ? transcript[transcript.length - 1].latency?.toFixed(2)
  //     : null;
  const latestEndLatency =
    transcript.length > 0
      ? transcript[transcript.length - 1].end_latency?.toFixed(2)
      : null;

  // const averageStartLatency =
  //   transcript.reduce((sum, item) => sum + (item.latency || 0), 0) /
  //   transcript.length;
  const averageEndLatency =
    transcript.reduce((sum, item) => sum + (item.end_latency || 0), 0) /
    transcript.length;

  // const formattedAverageStartLatency = averageStartLatency
  //   ? Math.floor(averageStartLatency)
  //   : "N/A";
  const formattedAverageEndLatency = averageEndLatency
    ? Math.floor(averageEndLatency)
    : "N/A";

  return (
    <div className="flex flex-col space-y-2 text-xs text-gray-500 bg-gray-50 w-full p-2 px-3 border-b border-gray-300">
      {/* <div className="flex flex-row space-x-4">
        <div>Start Latency:</div>
        <div>{latestStartLatency}ms</div>
        <div>{formattedAverageStartLatency}ms (Average)</div>
      </div> */}
      <div className="flex flex-row space-x-4">
        <div>Latency:</div>
        <div>{latestEndLatency}ms</div>
        <div>{formattedAverageEndLatency}ms (Average)</div>
      </div>
    </div>
  );
};
