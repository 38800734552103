import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import type {
  BaseClientMessage,
  ClientMessage,
} from "party/clientMessageTypes";
import type PartySocket from "partysocket";
import type { ReactNode } from "react";
import { useState, FormEvent, useCallback, useMemo } from "react";
import type { TranscriptUpdate } from "schemas/TranscriptUpdate";

export const CallTestingPanel = ({
  botSocket,
  transcript,
}: {
  botSocket: PartySocket;
  transcript: TranscriptUpdate[];
}) => {
  const maxTime = useMemo(() => {
    return Math.max(
      ...transcript.flatMap(
        (item) => item.words?.map((word) => word.end_time) ?? []
      ),
      0 // Fallback value if the array is empty
    );
  }, [transcript]);
  return (
    <div className="flex w-full flex-col space-y-4 p-4 divide-y border-gray-400">
      <CallTestingSection title="Simulate a conversation">
        <div className="flex flex-col space-y-4">
          <SimulateConversation
            title={"Speak as the host"}
            mode={"host"}
            botSocket={botSocket}
            time={maxTime}
          />

          <SimulateConversation
            title={"Speak as the guest"}
            mode={"guest"}
            botSocket={botSocket}
            time={maxTime}
          />
        </div>
      </CallTestingSection>
      <CallTestingSection title="Simulate events">
        <SimulateEventsMenu botSocket={botSocket} />
      </CallTestingSection>
      <CallTestingSection title="Run a script">Coming soon…</CallTestingSection>
    </div>
  );
};

export const SimulateEventsMenu = ({
  botSocket,
}: {
  botSocket: PartySocket;
}) => {
  const sendClientEvent = useCallback(
    (message: BaseClientMessage) => {
      const messageWithTimestamp: ClientMessage = {
        ...message,
        timestamp: new Date().toISOString(),
      };
      console.log("Sending client event:", messageWithTimestamp);
      botSocket.send(JSON.stringify(messageWithTimestamp));
    },
    [botSocket]
  );

  const handleReset = useCallback(() => {
    sendClientEvent({ type: "RESET" });
  }, [sendClientEvent]);

  const handleSimulateBuyingSignal = useCallback(() => {
    sendClientEvent({ type: "SIMULATE_BUYING_SIGNAL" });
  }, [sendClientEvent]);

  const handleSimulateQuestion = useCallback(() => {
    sendClientEvent({ type: "SIMULATE_QUESTION" });
  }, [sendClientEvent]);

  const handleSimulateConversation = useCallback(() => {
    sendClientEvent({ type: "SIMULATE_CONVERSATION" });
  }, [sendClientEvent]);

  const handleSimulateFullScript = useCallback(() => {
    sendClientEvent({ type: "SIMULATE_FULL_SCRIPT" });
  }, [sendClientEvent]);

  // const handleSimulateCurrentScriptItem = useCallback(() => {
  //   console.log("Simulating current script item", currentScriptItem);
  //   if (!currentScriptItem) {
  //     console.log("No current script item to simulate");
  //     return;
  //   }
  //   sendClientEvent({
  //     type: "SIMULATE_CURRENT_SCRIPT_ITEM",
  //     payload: currentScriptItem,
  //   });
  // }, [sendClientEvent, currentScriptItem]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm" variant="outline">
          Select event
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={handleSimulateBuyingSignal}>
          Simulate buying signal
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleSimulateQuestion}>
          Simulate question
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleSimulateConversation}>
          Simulate conversation
        </DropdownMenuItem>
        {/* <DropdownMenuItem onClick={handleSimulateCurrentScriptItem}>
          Simulate current script item
        </DropdownMenuItem> */}
        <DropdownMenuItem onClick={handleSimulateFullScript}>
          Simulate full script
        </DropdownMenuItem>
        <DropdownMenuItem onClick={handleReset}>Reset</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const SimulateConversation = ({
  title,
  mode,
  botSocket,
  time,
}: {
  title: string;
  mode: string;
  botSocket: PartySocket;
  time: number;
}) => {
  const [inputText, setInputText] = useState("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputText.trim()) {
      botSocket.send(
        JSON.stringify({
          type: "SIMULATE_TRANSCRIPT",
          payload: { mode, text: inputText.trim(), time: time + 1 },
          timestamp: new Date().toISOString(),
        })
      );
      setInputText("");
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <h3 className="font-bold">{title}</h3>
      <form onSubmit={handleSubmit} className="flex space-x-4">
        <input
          type="text"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          className="flex-grow px-2 py-1 border rounded"
          placeholder="Enter text..."
        />
        <button
          type="submit"
          className="px-4 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Speak
        </button>
      </form>
    </div>
  );
};

export const CallTestingSection = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex w-full flex-col space-y-4 p-4">
      <div>
        <h2 className="text-lg font-bold">{title}</h2>
      </div>
      <div>{children}</div>
    </div>
  );
};
