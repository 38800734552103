import { useUser } from "@clerk/clerk-react";

export const useFeatureFlag = (featureFlag: string) => {
  const { user } = useUser();
  const emails = [user?.primaryEmailAddress, user?.emailAddresses]
    .flat()
    .filter((e) => !!e);

  const allowedDomains = [
    "frontierxai.com",
    "frontierx.ai",
    "frontieracademy.ai",
  ];

  return (
    emails?.some((email) =>
      allowedDomains.some((domain) => email.emailAddress.endsWith(`@${domain}`))
    ) ?? false
  );
};
