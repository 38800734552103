import { TranscriptQuestionClassificationSchema } from "./TranscriptQuestionClassification";
import { WebhookTranscriptUpdateSchema } from "party/types";
import { z } from "zod";

export const TranscriptUpdateSchema = WebhookTranscriptUpdateSchema.extend({
  id: z.string(),
  latency: z.number().optional(),
  end_latency: z.number().optional(),
  classification: TranscriptQuestionClassificationSchema.optional(),
});

export type TranscriptUpdate = z.infer<typeof TranscriptUpdateSchema>;
