import { z } from "zod";

export const DigDeeperOpportunitySchema = z.object({
  opportunity: z.string(),
  explanation: z.string(),
  confidence: z.number(),
  context: z.string(),
});

export const DigDeeperOpportunityMessageSchema = z.object({
  type: z.literal("dig_deeper_opportunity"),
  data: DigDeeperOpportunitySchema,
});

export type DigDeeperOpportunityMessage = z.infer<
  typeof DigDeeperOpportunityMessageSchema
>;
export type DigDeeperOpportunity = z.infer<typeof DigDeeperOpportunitySchema>;
