import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import PartySocket from "partysocket";
import type { CallScript } from "./Script/types/CallScript";
import { useOrganization } from "@clerk/clerk-react";
import {
  CallScriptsResponseSchema,
  type CallScriptsResponse,
} from "./Script/CallScriptManagerUI";
import { useQuery } from "@tanstack/react-query";

export const CallScriptSelector = ({
  selectedScript,
  mainSocket,
  onSelect,
  label = "Select Script",
  size = "default",
}: {
  selectedScript: CallScript | null;
  mainSocket: PartySocket;
  onSelect: (c: CallScript) => void;
  label?: string;
  size?: "sm" | "default" | "lg";
}) => {
  const { organization } = useOrganization();

  const fetchCallScripts = async (
    orgId: string
  ): Promise<CallScriptsResponse> => {
    const response = await PartySocket.fetch(
      {
        host: mainSocket.host,
        party: "main",
        room: orgId,
        path: "api/callscripts/list",
      },
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orgId }),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    return CallScriptsResponseSchema.parse(result);
  };

  const {
    data: callScripts,
    isLoading: isLoadingScripts,
    isError: isErrorScripts,
  } = useQuery<CallScriptsResponse, Error>({
    queryKey: ["callScripts", organization?.id],
    queryFn: () => fetchCallScripts(organization?.id || ""),
    enabled: !!organization?.id,
  });
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline" disabled={isLoadingScripts} size={size}>
          {selectedScript ? selectedScript.title : label}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {isLoadingScripts ? (
          <DropdownMenuItem disabled>Loading scripts...</DropdownMenuItem>
        ) : isErrorScripts ? (
          <DropdownMenuItem disabled>Error loading scripts</DropdownMenuItem>
        ) : callScripts && callScripts.length > 0 ? (
          callScripts.map((script) => (
            <DropdownMenuItem key={script.id} onClick={() => onSelect(script)}>
              {script.title}
            </DropdownMenuItem>
          ))
        ) : (
          <DropdownMenuItem disabled>
            Add a call script in settings
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
