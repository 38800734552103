import { z } from "zod";
import { TranscriptUpdateSchema } from "./TranscriptUpdate";
import {
  AILatencyMetricsSchema,
  AIQuestionAnswerSchema,
  AIStreamingStatusSchema,
  CallQuestionSchema,
  MeetingParticipantSchema,
  PartialAIResponseSchema,
  PlaybookProgressSchema,
  PlaybookScriptItemSchema,
  PlaybookSectionSchema,
  RecallBotSchema,
  StatusChangeSchema,
} from "../party/types";
import { SummaryOutputSchema } from "../party/logic/SummaryGenerator/SummaryGenerator";

export const ServerMessageSchema = z.discriminatedUnion("type", [
  z.object({
    type: z.literal("FULL_TRANSCRIPT"),
    payload: z.array(TranscriptUpdateSchema),
  }),
  z.object({
    type: z.literal("AI_HISTORY"),
    payload: z.array(AIQuestionAnswerSchema),
  }),
  z.object({
    type: z.literal("TRANSCRIPT_UPDATE"),
    payload: TranscriptUpdateSchema,
  }),
  z.object({
    type: z.literal("STATUS_UPDATE"),
    payload: z.object({
      status: StatusChangeSchema,
    }),
  }),
  z.object({
    type: z.literal("AI_HELP_STREAM_INFO"),
    payload: AIStreamingStatusSchema,
  }),
  z.object({
    type: z.literal("AI_HELP_STREAM"),
    payload: PartialAIResponseSchema,
  }),
  z.object({
    type: z.literal("AI_HELP_STREAM_END"),
    payload: PartialAIResponseSchema,
  }),
  z.object({
    type: z.literal("AI_LATENCY_METRICS"),
    payload: AILatencyMetricsSchema,
  }),
  z.object({
    type: z.literal("AI_ERROR"),
    payload: z.object({
      message: z.string(),
    }),
  }),
  z.object({
    type: z.literal("ERROR"),
    payload: z.object({
      message: z.string(),
    }),
  }),
  z.object({
    type: z.literal("BOT_INFO"),
    payload: RecallBotSchema,
  }),
  z.object({
    type: z.literal("CURRENT_PLAYBOOK_SECTION"),
    payload: z.string(),
  }),
  z.object({
    type: z.literal("PLAYBOOK_SECTION_UPDATE"),
    payload: PlaybookSectionSchema,
  }),
  z.object({
    type: z.literal("PLAYBOOK_PROGRESS"),
    payload: PlaybookProgressSchema,
  }),
  z.object({
    type: z.literal("CURRENT_SCRIPT_ITEM"),
    payload: PlaybookScriptItemSchema,
  }),
  z.object({
    type: z.literal("PLAYBOOK_COMPLETE"),
  }),
  z.object({
    type: z.literal("CALL_QUESTIONS_UPDATE"),
    payload: z.array(CallQuestionSchema),
  }),
  z.object({
    type: z.literal("MESSAGE_LATENCY"),
    payload: z.number(),
  }),
  z.object({
    type: z.literal("PROCESS_SCRIPT"),
    payload: z.boolean(),
  }),
  z.object({
    type: z.literal("PARTICIPANTS"),
    payload: z.array(MeetingParticipantSchema),
  }),
  z.object({
    type: z.literal("CALL_SUMMARY"),
    payload: SummaryOutputSchema,
  }),
  z.object({
    type: z.literal("CALL_SUMMARY_REQUESTED"),
  }),
]);

export type ServerMessage = z.infer<typeof ServerMessageSchema>;
