import { z } from "zod";

export const TranscriptQuestionClassificationSchema = z.enum([
  "meaningful_question",
  "irrelevant_question",
  "non_question",
  "unknown",
]);

export type TranscriptQuestionClassification = z.infer<
  typeof TranscriptQuestionClassificationSchema
>;
