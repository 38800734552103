import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Check, X } from "lucide-react";
import type { CallDisplayMode } from "./CallDisplayUI";

export function CallDoneDialog({
  setActiveMode,
  leaveCall,
}: {
  setActiveMode: React.Dispatch<React.SetStateAction<CallDisplayMode>>;
  leaveCall: () => void;
}) {
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button size="sm" variant="default">
          <Check size={16} className="mr-2" /> Done
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <div className="flex flex-row items-center justify-between">
            <div className="flex-grow">
              <AlertDialogTitle>Leave call </AlertDialogTitle>
            </div>
            <div>
              <AlertDialogCancel>
                <X size={16} />
              </AlertDialogCancel>
            </div>
          </div>
          <AlertDialogDescription>
            Would you like to view your report for this call?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => leaveCall()}>
            Leave call
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => setActiveMode("report")}>
            View report
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
