import { useCallDisplay } from "./useCallDisplay";
import { CallDisplayUI } from "./CallDisplayUI";
import type PartySocket from "partysocket";
import { CallTestingPanel } from "./CallTestingPanel";
import { TestPortal } from "./CallManagerWrapper";

export function CallDisplay({
  mainSocket,
  host,
  roomId,
  leaveCall,
}: Readonly<{
  mainSocket: PartySocket;
  roomId: string;
  leaveCall: () => void;
  host: string;
}>) {
  const callDisplayProps = useCallDisplay(roomId, host);
  const { reconnectAttempt, ...callDisplayUIProps } = callDisplayProps;
  const { transcript } = callDisplayProps;
  return (
    <>
      <CallDisplayUI
        key={reconnectAttempt}
        {...callDisplayUIProps}
        leaveCall={leaveCall}
        host={host}
        roomId={roomId}
        mainSocket={mainSocket}
      />
      <TestPortal>
        <CallTestingPanel
          botSocket={callDisplayProps.botSocket}
          transcript={transcript}
        />
      </TestPortal>
    </>
  );
}
