import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import useLocalStorageState from "use-local-storage-state";
import ReactMarkdown from "react-markdown";

import { RecallBot } from "party/types";
import { CheckCircle, MessageCircleQuestion, Sparkles } from "lucide-react";
import { useCallback, useState } from "react";
import { Question, QuestionSchema } from "../../../schemas/Question";
import { AiHelp } from "./AiHelpBrowser";
import { HandleGetHelpFunction } from "./AiHelpCard";
import { usePartySocketWithReconnect } from "../hooks/usePartySocketWithReconnect";
import {
  GuestHostFilterDropdown,
  type GuestHostFilter,
} from "./GuestHostFilterDropdown";
import type { CallDisplayMode } from "./CallDisplayUI";
import { PanelHeader } from "./PanelHeader";

export interface QuestionDisplayProps {
  host: string;
  roomId: string;
  onGetAIHelp: HandleGetHelpFunction;
  roomBot: RecallBot | undefined;
  aiHistory: AiHelp[];
  handleGetHelp: HandleGetHelpFunction;
  setActiveMode: React.Dispatch<React.SetStateAction<CallDisplayMode>>;
  isStreaming: boolean;
}

export function QuestionDisplay({
  host,
  roomId,
  onGetAIHelp,
  roomBot,
  aiHistory,
  handleGetHelp,
  setActiveMode,
  isStreaming,
}: Readonly<QuestionDisplayProps>) {
  const [displayFilter, setDisplayFilter] =
    useLocalStorageState<GuestHostFilter>("question-display-filter", {
      defaultValue: "guest",
    });
  const [callQuestions, setCallQuestions] = useState<Question[]>([]);
  const { socket: questionSocket, reconnectAttempt } =
    usePartySocketWithReconnect({
      host,
      room: roomId,
      party: "questions",
      onMessage(event) {
        const data = JSON.parse(event.data);
        if (data.type === "QUESTIONS") {
          setCallQuestions(
            data.payload.sort((a: Question, b: Question) =>
              b.id.localeCompare(a.id)
            )
          );
        }
        if (data.type === "QUESTION") {
          const newQuestion = QuestionSchema.parse(data.payload);
          setCallQuestions((prevQuestions) => {
            const existingIndex = prevQuestions.findIndex(
              (q) => q.id === newQuestion.id
            );
            let updatedQuestions;
            if (existingIndex !== -1) {
              // Replace existing question
              updatedQuestions = [
                ...prevQuestions.slice(0, existingIndex),
                newQuestion,
                ...prevQuestions.slice(existingIndex + 1),
              ];
            } else {
              // Append new question
              updatedQuestions = [...prevQuestions, newQuestion];
            }
            return updatedQuestions.sort((a, b) => b.id.localeCompare(a.id));
          });
        }
      },
    });

  const handleAnswerQuestion = useCallback(
    (questionId: string) => {
      questionSocket.send(
        JSON.stringify({
          type: "ANSWER_QUESTION",
          payload: { questionId },
        })
      );
    },
    [questionSocket]
  );

  const filteredQuestions = callQuestions.filter((q) => {
    const speaker = roomBot?.meeting_participants?.find(
      (p) => p.id === q.speaker_id
    );
    if (!speaker) return true;

    switch (displayFilter) {
      case "all":
        return true;
      case "host":
        return speaker.is_host;
      case "guest":
        return !speaker.is_host;
      default:
        return false;
    }
  });

  const displayedQuestions = filteredQuestions;
  return (
    <div className="h-full flex flex-col" key={reconnectAttempt}>
      <PanelHeader title="Questions">
        <div className="flex flex-row w-full space-x-2">
          <div className="flex-grow-0">
            <GuestHostFilterDropdown
              value={displayFilter}
              onSelect={setDisplayFilter}
            />
          </div>
          <div className="flex-grow-0">
            <Button
              variant="outline"
              size="sm"
              onClick={() => {
                handleGetHelp({});
                setActiveMode("ai");
              }}
              disabled={isStreaming}
            >
              <Sparkles size={16} className="mr-2" />
              {isStreaming ? "Getting AI Help..." : "Get AI Help"}
            </Button>
          </div>
        </div>
      </PanelHeader>
      <div className="flex-grow overflow-hidden flex flex-col">
        <ScrollArea className="flex-grow">
          <QuestionsList
            onGetAIHelp={onGetAIHelp}
            questions={displayedQuestions}
            onAnswerQuestion={handleAnswerQuestion}
            aiHistory={aiHistory}
          />
        </ScrollArea>
      </div>
    </div>
  );
}

const QuestionsList = ({
  questions,
  onAnswerQuestion,
  onGetAIHelp,
  aiHistory,
}: {
  questions: Question[];
  onAnswerQuestion: (questionId: string) => void;
  onGetAIHelp: HandleGetHelpFunction;
  aiHistory: AiHelp[];
}) => {
  return (
    <ul>
      {questions
        .sort((a, b) => parseInt(b.id) - parseInt(a.id))
        .map((question) => (
          <QuestionItem
            key={question.id}
            question={question}
            onAnswerQuestion={onAnswerQuestion}
            onGetAIHelp={onGetAIHelp}
            aiHelp={aiHistory.find((ai) => ai.questionId === question.id)}
          />
        ))}
      {questions.length === 0 && (
        <p className="text-sm text-gray-400 p-4">
          Questions asked during the call will appear here
        </p>
      )}
    </ul>
  );
};
export interface QuestionItemProps {
  aiHelp: AiHelp | undefined;
  question: Question;
  onAnswerQuestion: (questionId: string) => void;
  onGetAIHelp: HandleGetHelpFunction;
}

function QuestionItem({
  aiHelp,
  question,
  onAnswerQuestion,
  onGetAIHelp,
}: Readonly<QuestionItemProps>) {
  return (
    <li className="flex flex-col p-2 border-b border-gray-100 last:border-b-0">
      <div className="flex items-center justify-between space-x-2">
        <div className="flex-grow-0">
          <Button
            size="sm"
            variant={"ghost"}
            className="px-2"
            onClick={() => onAnswerQuestion(question.id)}
            disabled={question.isAnswered}
          >
            {question.isAnswered ? (
              <CheckCircle size={16} />
            ) : (
              <MessageCircleQuestion size={16} />
            )}
          </Button>
        </div>
        <div className="font-semibold flex-grow">
          <div>{question.question}</div>
        </div>

        <div className="flex-grow-0 pr-2">
          <Button
            size="sm"
            className="px-2"
            variant="outline"
            onClick={() =>
              onGetAIHelp({
                detectedQuestion: question,
              })
            }
          >
            <Sparkles size={16} className="mr-1" />
          </Button>
        </div>
      </div>
      {/* <div className="text-sm text-gray-600 px-2">{question.context}</div> */}
      {aiHelp && (
        <div className="text-sm text-gray-600 px-2">
          <ReactMarkdown className="prose prose-sm max-w-none dark:prose-invert">
            {aiHelp.answer}
          </ReactMarkdown>
        </div>
      )}
    </li>
  );
}
