export type IntegrationConnectionStatusProps = {
  connected: boolean;
  status: string | null;
};

export const IntegrationConnectionStatus = ({
  connected,
  status,
}: Readonly<IntegrationConnectionStatusProps>) => {
  const statusText = connected
    ? "Connected"
    : status === null
      ? "Not connected"
      : status;
  const statusColor = connected
    ? "bg-green-500"
    : status
      ? "bg-orange-500"
      : "bg-slate-400";
  const capitalizedStatusText =
    statusText.charAt(0).toUpperCase() + statusText.slice(1);

  return (
    <div className="flex items-center space-x-2">
      <div className={`w-3 h-3 rounded-full ${statusColor}`} />
      <div>{capitalizedStatusText}</div>
    </div>
  );
};
