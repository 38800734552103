import type { ReactNode } from "react";

export const PanelHeader = ({
  title,
  children,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <div className="flex flex-row w-full border-b border-gray-300 items-center justify-between">
      <h2 className="text-md font-semibold truncate flex-shrink min-w-0 mr-4 pl-3">
        {title}
      </h2>
      <div className="flex items-center space-x-2 flex-shrink-0 p-2">
        {children}
      </div>
    </div>
  );
};
