import { z } from "zod";

export const BuyingSignalSchema = z.object({
  signal: z.string(),
  explanation: z.string(),
  confidence: z.number(),
  context: z.string(),
});

export const BuyingSignalMessageSchema = z.object({
  type: z.literal("buying_signal_detected"),
  data: BuyingSignalSchema,
});

export type BuyingSignalMessage = z.infer<typeof BuyingSignalMessageSchema>;
export type BuyingSignal = z.infer<typeof BuyingSignalSchema>;
